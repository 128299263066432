
.App {
  text-align: center;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.col {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.social {
  margin: 7px;
  font-size: 40px;
  cursor: pointer;
  transition: all 0.2s ease 0s;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #969292;
  border: 0px none #ffffff;
  border-radius: 47px;
}
::-webkit-scrollbar-thumb:hover {
  background: #666666;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #cfc9c9;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #d7d5d5;
}
::-webkit-scrollbar-track:active {
  background: #969292;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
